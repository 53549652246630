import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import SessionModule from "@/store/SessionModule";
import {getModule} from "vuex-module-decorators";
import SnackbarModule from "@/store/SnackbarModule";

export default class ProductFeatureService {


    static postProductFeature(component: Vue, productId: number, productFeatureTypeId: number, value: string) {
        (<any>component).loading = true
        let formData: FormData = new FormData()
        formData.set("productFeatureTypeId", productFeatureTypeId.toString())
        formData.set("value", value)
        component.axios.post(ConstantTool.BASE_URL + "/api/products/" + productId + "/product-features/",
            formData, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(() => (<any>component).refresh())
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo añadir la característica"))
            .finally(() => (<any>component).loading = false)
    }

    static deleteProductFeature(component: Vue, productFeatureId: number) {
        (<any>component).loading = true
        component.axios.delete(ConstantTool.BASE_URL + "/api/product-features/" + productFeatureId, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(() => (<any>component).refresh())
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo eliminar la característica"))
            .finally(() => (<any>component).loading = false)
    }

}

import Translation, {EntityType, LanguageType} from "@/models/Translation";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import {Vue} from "vue-property-decorator";
import SnackbarModule from "@/store/SnackbarModule";
import JsonTool from "@/services/tool/JsonTool";
import Product from "@/models/Product";
import {AxiosError} from "axios";

export default class TranslationService {

    static async getTranslations (component: Vue, translations: Translation[], entity: EntityType, language: LanguageType, entityId: number) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/translations", {
                params: { entity, language, entityId },
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            translations.splice(0, translations.length)
            // @ts-ignore
            JsonTool.jsonConvert.deserializeArray(response.data, Translation).forEach(t => translations.push(t))
        } catch (e) {
            console.log(e)
            let error = e as AxiosError
            if (error.response?.status == 404) {
                console.log("Traduccion inexistente")
                return 404
            }
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async postTranslation(component: Vue, translation: Translation) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.post(ConstantTool.BASE_URL + "/api/translations", translation, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se han podido crear las traducciones.")
        } finally {
            // @ts-ignore
            component.loading = false
        }

    }

    static async patchTranslation(component: Vue, id: number, translation: Translation) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.patch(ConstantTool.BASE_URL + "/api/translations/" + translation.id, translation,{
                headers: { Authorization: getModule(SessionModule).session.token }
            })
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se ha podido actualizar la traducción.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }



}


















































































































import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ProductService from "@/services/ProductService";
import Product from "@/models/Product";
import LangModule from "@/store/LangModule";
import FilePickerComponent from "@/components/FilePickerComponent.vue";

@Component( {components: { FilePickerComponent }})
export default class ProductDocumentTab extends Vue {

	@Ref() readonly formDocuments!: HTMLFormElement
	lang: any = getModule(LangModule).lang
	product: Product = new Product()
	document: File | null = null
	image: File | null = null
	loading: boolean = false
	dialog: boolean = false

	get baseName() {
		return this.product.document?.baseName
	}

	created() {
		this.refresh()
	}

	async refresh() {
		await ProductService.getProduct(this, Number(this.$route.params.productId))
	}

	downloadDocument() {
		let link = document.createElement("a")
		link.href = this.product.document!!.url!!
		link.target = "_blank"
		link.download = "filename"
		link.click();
	}

	async updateProductDocuments() {
		// @ts-ignore
		if (this.formDocuments.validate() && this.$refs.filePicker.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				 "Aviso",
				 "¿Está seguro de actualizar los documentos?",
				 async () => {
					 await ProductService.patchProductDocuments(this, Number(this.$route.params.productId), this.image!, this.document!)
					 this.dialog = false
					 // @ts-ignore
					 this.$refs.filePicker.clear()
					 this.document = null
				 }
			))
		}
	}

	async clearProductDocument() {
		getModule(DialogModule).showDialog(new Dialog(
			 "Aviso",
			 "¿Está seguro de querer eliminar los documentos adjuntos al producto?",
			 async () => {
				 await ProductService.clearProductDocuments(this, Number(this.$route.params.productId))
				 this.product.document = undefined
				 this.document = null
			 }
		))
	}

}
